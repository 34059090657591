import React from 'react';
import SignIn from './sign_in';
import RegistrationLinksPanel from './registration_links_panel';
import '../../../sass/components/auth/user_auth.scss';

const UserAuth = (props) => (
  <div className="user-auth">
    <SignIn
      subDir={props.siteData.sub_dir}
      localisations={props.localisations}
      logInFromUrl={props.logInFromUrl}
      csrfToken={props.csrfToken}
      facebookLoginActive={props.facebookLoginActive}
      userType={props.userType}
    />
    <RegistrationLinksPanel
      subDir={props.siteData.sub_dir}
      localisations={props.localisations}
      logInFromUrl={props.logInFromUrl}
      facebookLoginActive={props.facebookLoginActive}
    />
  </div>
);

export default UserAuth;
