import React, { FunctionComponent } from 'react';
import BaseBox, { MessageBoxProps } from './BaseBox';

const MessageBox: FunctionComponent<MessageBoxProps> = ({
  type,
  size,
  children,
}) => {
  return (
    <BaseBox type={type} size={size}>
      <div className="message-box__content">{children}</div>
    </BaseBox>
  );
};

export const MessageBoxSimple: FunctionComponent<MessageBoxProps> = ({
  type,
  size,
  children,
}) => {
  return (
    <BaseBox type={type} size={size}>
      <p className="message-box__text">{children}</p>
    </BaseBox>
  );
};

export default MessageBox;
