import React, { Component } from 'react';
import { FacebookButton, FacebookOfflineButton } from '../buttons/facebook';
import { MessageBoxSimple } from '../MessageBox';
import trackEvent from '../../helpers/gtm/eventPush';

import '../../../sass/components/forms.scss';
import '../../../sass/components/auth/sign_in.scss';

const password = document.querySelector('.auth').dataset.pass;
const { email } = document.querySelector('.auth').dataset;

class signIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rememberMeValue: 'N',
      showFacebookOffline: false,
    };
    this.updateRememberMe = this.updateRememberMe.bind(this);
    this.updateShowFacebookOffline = this.updateShowFacebookOffline.bind(this);
  }

  updateRememberMe() {
    this.setState((prevState) => {
      if (prevState.rememberMeValue === 'Y') {
        return { rememberMeValue: 'N' };
      }
      return { rememberMeValue: 'Y' };
    });
  }

  updateShowFacebookOffline() {
    this.setState(() => {
      return { showFacebookOffline: true };
    });
  }

  render() {
    return (
      <div className="sign-in">
        <div className="sign-in__facebook-wrapper">
          {this.props.facebookLoginActive ? (
            <>
              <FacebookButton
                id="signInFB"
                wide={true}
                href={`/${this.props.subDir}/logon.pl?loginfrom_url=${this.props.logInFromUrl}`}
                text={this.props.localisations.continue_with_facebook}
                title={this.props.localisations.login_with_facebook}
              />
              <p className="sign-in__or">
                <span>{this.props.localisations.or}</span>
              </p>
            </>
          ) : this.state.showFacebookOffline ? (
            <>
              <MessageBoxSimple type="warning">
                <span>Facebook login is currently unavailable</span>
              </MessageBoxSimple>
              <p className="sign-in__email-password">
                Please login with your email and password
              </p>
            </>
          ) : (
            <>
              <FacebookOfflineButton
                click={this.updateShowFacebookOffline}
                wide={true}
                text={this.props.localisations.continue_with_facebook}
              />
              <p className="sign-in__or">
                <span>{this.props.localisations.or}</span>
              </p>
            </>
          )}
        </div>
        <div className="sign-in__form">
          <form
            action={`/${this.props.subDir}/logon.pl`}
            method="POST"
            name="sign-in"
            className="form"
          >
            <input
              type="hidden"
              name="loginfrom_url"
              value={this.props.logInFromUrl}
            />
            <div className="form-group form-group--no-validation">
              <label className="form-group__title" htmlFor="loginemail">
                {this.props.localisations.email}
                {this.state.showFacebookOffline ? (
                  <span className="form-group__hint">
                    * This may be your Facebook email
                  </span>
                ) : null}
              </label>
              <input
                className="form-group__input form-group__input--wide"
                type="email"
                name="email"
                maxLength="255"
                defaultValue={`${email}`}
                required
                id="loginemail"
              />
              {this.props.csrfToken ? (
                <input
                  id="csrf-token"
                  type="hidden"
                  name="csrf_token"
                  value={this.props.csrfToken}
                ></input>
              ) : null}
              {this.props.userType ? (
                <input
                  id="usertype"
                  type="hidden"
                  name="usertype"
                  value={this.props.userType}
                />
              ) : null}
            </div>
            <div className="form-group form-group--no-validation">
              <label className="form-group__title" htmlFor="loginpass">
                {this.props.localisations.password}
              </label>
              <input
                className="form-group__input form-group__input--wide"
                type="password"
                name="password"
                maxLength="255"
                defaultValue={`${password}`}
                required
                id="loginpass"
              />
            </div>
            <div className="form-group form-group--no-validation form-group--flex">
              <div className="form-group__checkbox">
                <input
                  type="checkbox"
                  id="remember_me_checkbox"
                  onChange={this.updateRememberMe}
                />
                <label htmlFor="remember_me_checkbox">
                  {this.props.localisations.remember_me}
                </label>
              </div>
              <input
                type="hidden"
                name="remember_me"
                value={this.state.rememberMeValue}
              />
              <div className="sign-in__button">
                <button
                  className="button button--wide"
                  id="sign-in-button"
                  type="submit"
                  name="sign-in-button"
                >
                  {this.props.localisations.login}{' '}
                </button>
              </div>
              <a
                href={`/${this.props.subDir}/passwordreminder.pl`}
                rel="nofollow"
                className="sign-in__forgotten-password"
                onClick={trackForgotPassword}
              >
                {this.state.showFacebookOffline
                  ? "I don't know my SpareRoom password"
                  : this.props.localisations.forgot_your_password}
              </a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
function trackForgotPassword() {
  trackEvent({
    category: 'login',
    action: 'login_forgot_password',
  });
}
export default signIn;
