import React from 'react';
import { FacebookButton } from '../buttons/facebook';
import SecondaryButton from '../buttons/secondary';
import '../../../sass/components/auth/registration_links_panel.scss';

const registrationLinksPanel = (props) => (
  <div className="register-links">
    <div className="register-links__container">
      <h3 className="register-links__heading">
        {props.localisations.not_registered_join_us}
      </h3>
      {props.facebookLoginActive ? (
        <div className="register-links__facebook">
          <FacebookButton
            wide={true}
            href={`/${props.subDir}/logon.pl?loginfrom_url=${props.logInFromUrl}`}
            text={props.localisations.register_with_facebook}
            title={props.localisations.register_with_facebook}
            id="registerFB"
          />
        </div>
      ) : null}
      <div className="register-links__email">
        <SecondaryButton
          wide={true}
          href={`/${props.subDir}/reg.pl?loginfrom_url=${props.logInFromUrl}`}
          text={props.localisations.register_via_email}
        />
      </div>
    </div>
  </div>
);

export default registrationLinksPanel;
