import React from 'react';

// Parameters:
// href (string) - required
// text (string) - required
// wide (boolean) - optional - defaults to false
// tabIndex (string) - optional - defaults to nothing
// title (sting) - optional - defualts to "Share with Facebook"
// connect

const FacebookButton = (props) => (
  <a
    data-facebook-connect=""
    className={
      props.wide
        ? 'button button--facebook button--wide'
        : 'button button--facebook'
    }
    title={props.title ? props.title : 'Share with Facebook'}
    href={props.href}
    tabIndex={props.tabIndex ? props.tabIndex : ''}
    {...(props.connect && { dataFacebookConnect: true })}
    id={props.id}
  >
    <div className="button__content">
      <span className="button__icon">
        <i className="fab fa-facebook" />
      </span>
      <span className="button__text">{props.text}</span>
    </div>
  </a>
);

// Parameters:
// click (function) - required
// text (string) - required
// wide (boolean) - optional - defaults to false

const FacebookOfflineButton = (props) => (
  <button
    onClick={props.click}
    className={
      props.wide
        ? 'button button--facebook button--wide'
        : 'button button--facebook'
    }
    id={props.id}
  >
    <div className="button__content">
      <span className="button__icon">
        <i className="fab fa-facebook" />
      </span>
      <span className="button__text">{props.text}</span>
    </div>
  </button>
);

export { FacebookButton, FacebookOfflineButton };
