import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { createPortal } from 'react-dom';
import FocusTrap from 'focus-trap-react';
import { fetchData } from '../../helpers/base_data_fetcher';
import useKeyPress from '../../helpers/hooks/UseKeyPress';
import '../../../sass/components/modal.scss';

export type ModalProps = {
  close: () => void;
  onOpen?: () => void;
  onUserClose?: () => void;
  id: string;
  wrapperClass?: string;
  modalLabelText: string;
  showDialog: boolean;
  initialFocus?: string;
  fullBleed?: boolean;
  large?: boolean;
  showCloseButton?: boolean;
  overlayClass?: string;
};

const Modal: FunctionComponent<ModalProps> = ({
  close,
  onOpen = () => null,
  onUserClose = (method: string) => null,
  children,
  id,
  wrapperClass,
  modalLabelText,
  showDialog,
  initialFocus,
  fullBleed,
  large,
  showCloseButton = true,
  overlayClass = null,
}) => {
  const [isOpen, updateIsOpen] = useState(false);
  const [isClosing, updateIsClosing] = useState(false);
  const [localisations, setLocalisations] = useState({});
  const componentMounted = useRef(true);

  function handleClose() {
    updateIsOpen(false);
    return updateIsClosing(true);
  }

  function userTriggeredClose(method: string) {
    onUserClose(method);
    handleClose();
  }

  useKeyPress('Escape', () => {
    onUserClose('Escape');
    handleClose();
  });

  useEffect(() => {
    fetchData('localisations.modal')
      .then((localisationsData) => {
        if (componentMounted.current) {
          setLocalisations(localisationsData);
        }
      })
      .catch((e) => {
        console.log('Error accessing localisations');
      });
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    updateIsOpen(showDialog);
  }, [showDialog]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('prevent-scroll');
      onOpen();
    }
    return () => document.body.classList.remove('prevent-scroll');
  }, [isOpen, onOpen]);

  useEffect(() => {
    const closeFunction = setTimeout(() => {
      if (isClosing) {
        document.body.classList.remove('prevent-scroll');
        updateIsClosing(false);
        close();
      }
    }, 300);
    return () => clearTimeout(closeFunction);
  }, [isClosing, close]);

  function getLocalisation(key: string) {
    if (key in localisations) {
      return localisations[key] as string;
    }
    return '';
  }

  return showDialog
    ? createPortal(
        <>
          <div
            className={`modal-overlay ${
              isOpen ? 'modal-overlay--open' : ''
            } ${overlayClass}`}
            onClick={() => userTriggeredClose('Click outside modal')}
          />
          <FocusTrap
            active={showDialog}
            focusTrapOptions={{
              allowOutsideClick: true,
              initialFocus: initialFocus || '',
            }}
          >
            <div
              className={`modal ${isOpen ? 'modal--open' : ''} ${
                large ? 'modal--large' : ''
              } ${wrapperClass || ''}`}
              id={`${id}-dialog`}
              aria-modal="true"
              role="dialog"
              aria-labelledby={`${id}-dialog-label`}
            >
              {modalLabelText ? (
                <div className="modal__header">
                  <h2
                    id={`${id}-dialog-label`}
                    role="status"
                    aria-live="assertive"
                  >
                    {modalLabelText}{' '}
                    <span className="sr-only">
                      {getLocalisation('dialog_window')}
                    </span>
                  </h2>
                </div>
              ) : null}
              {showCloseButton ? (
                <button
                  type="button"
                  className="modal__close"
                  data-dismiss="modal"
                  aria-label={getLocalisation('close')}
                  onClick={() => userTriggeredClose('Close button')}
                >
                  <i className="far fa-times" aria-hidden="true"></i>
                  <span>{getLocalisation('close')}</span>
                </button>
              ) : null}
              <div
                className={`modal__content ${
                  fullBleed ? 'modal__content--full-bleed' : ''
                }`}
              >
                {children}
              </div>
            </div>
          </FocusTrap>
        </>,
        document.body,
      )
    : null;
};

export default Modal;
