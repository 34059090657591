import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { fetchData } from '../../helpers/base_data_fetcher';
import Modal from '../Modal';
import UserAuth from './user_auth';
import facebookLogin from '../../services/facebookLogin/index';

const initiateFacebookLogin = () => {
  Promise.all([
    fetchData('page'),
    fetchData('site'),
    fetchData('affiliate'),
    fetchData('config'),
  ]).then(([page, site, affiliate, config]) => {
    facebookLogin.initialiseApp({ language: config.facebook.app_locale });
    const facebookButtons = document.querySelectorAll(
      '.user-auth [data-facebook-connect]',
    );
    facebookButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        facebookLogin.login(
          {
            loginfrom_url: page.loginReturnPath,
            referred_by: affiliate.id,
          },
          site.sub_dir,
        );
      });
    });
  });
};

const mountElement = document.getElementById('user_auth');
const container = document.getElementById('userAuth');

const Login = ({
  siteData,
  localisations,
  logInFromUrl,
  csrfToken,
  useModal,
  openModalOnLoad,
  facebookLoginActive,
  userType,
}) => {
  const [showDialog, updateShowDialog] = useState(false);

  const close = () => {
    updateShowDialog(false);
  };

  const open = () => {
    updateShowDialog(true);
    initiateFacebookLogin();
  };

  useEffect(() => {
    updateShowDialog(openModalOnLoad);
  }, [openModalOnLoad]);

  document.querySelectorAll('.show-user-auth-popup').forEach((link) => {
    link.onclick = (event) => {
      event.preventDefault();
      open();
    };
  });

  return (
    <>
      {useModal ? (
        <Modal
          close={close}
          showDialog={showDialog}
          modalLabelText={localisations.login}
          id="auth-modal"
          fullBleed={true}
          initialFocus={facebookLoginActive ? '#signInFB' : '#loginemail'}
        >
          <UserAuth
            siteData={siteData}
            localisations={localisations}
            logInFromUrl={logInFromUrl}
            csrfToken={csrfToken}
            facebookLoginActive={facebookLoginActive}
          />
        </Modal>
      ) : (
        <UserAuth
          siteData={siteData}
          localisations={localisations}
          logInFromUrl={encodeURIComponent(logInFromUrl)}
          csrfToken={csrfToken}
          facebookLoginActive={facebookLoginActive}
          userType={userType}
        />
      )}
    </>
  );
};

const initialiseApp = (
  siteData,
  localisations,
  logInFromUrl,
  csrfToken,
  useModal,
  openModalOnLoad,
  features,
  userType,
) => {
  ReactDOM.render(
    <Login
      siteData={siteData}
      localisations={localisations}
      logInFromUrl={logInFromUrl}
      csrfToken={csrfToken}
      useModal={useModal}
      openModalOnLoad={openModalOnLoad}
      facebookLoginActive={features.facebook_login_active}
      userType={userType}
    />,
    mountElement,
  );
};

const renderLogin = () => {
  const {
    logInFromUrl,
    useModal,
    showSignIn,
    csrfToken,
    userType,
  } = container.dataset;
  Promise.all([
    fetchData('site'),
    fetchData('localisations.auth'),
    fetchData('config.features'),
  ]).then(([data, localisations, features]) => {
    initialiseApp(
      data,
      localisations,
      logInFromUrl,
      csrfToken,
      useModal,
      showSignIn,
      features,
      userType,
    );
  });
};

if (mountElement) {
  document.addEventListener('DOMContentLoaded', () => {
    renderLogin();
  });
}
