import React, { FunctionComponent } from 'react';
import '../../../sass/components/message_box.scss';

function iconClass(type) {
  return type == 'success'
    ? 'far fa-check-circle'
    : type == 'error'
    ? 'far fa-exclamation-circle'
    : type == 'warning'
    ? 'far fa-exclamation-triangle'
    : type == 'info'
    ? 'far fa-info-circle'
    : type == 'tips'
    ? 'far fa-lightbulb-on'
    : '';
}

function sizeClass(size) {
  return size == 'small' ? ' message-box--small' : '';
}

export type MessageBoxProps = {
  type: string;
  size?: 'small';
};

const BaseBox: FunctionComponent<MessageBoxProps> = ({
  type,
  size,
  children,
}) => {
  return (
    <div className={`message-box message-box--${type}${sizeClass(size)}`}>
      <span className="message-box__icon">
        <i className={iconClass(type)} />
      </span>
      {children}
    </div>
  );
};

export default BaseBox;
