import React from 'react';

// Parameters:
// href (string) - required
// text (string) - required
// wide (boolean) - optional - defaults to false
// tabIndex (string) - optional - defaults to nothing

const secondaryButton = props => (
  <a
    className={
      props.wide
        ? 'button button--secondary button--wide'
        : 'button button--secondary'
    }
    href={props.href}
    tabIndex={props.tabIndex ? props.tabIndex : ''}
  >
    <div className="button__content">
      <span className="button__text">{props.text}</span>
    </div>
  </a>
);

export default secondaryButton;
